import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainAppBar, { ColorModeContext } from "./components/appBar/AppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import EncounterBuilder from "./components/encounterBuilder/EncounterBuilder";
import TreasureGenerator from "./components/treasureGenerator/TreasureGenerator";
import EncounterView from "./components/encounterBuilder/EncounterView";

function App() {
  const [mode, setMode] = React.useState<"light" | "dark">("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <div className="App">
            <MainAppBar />
            <React.Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route
                  path="/encounterBuilder"
                  element={<EncounterBuilder />}
                />
                <Route path="/treasure" element={<TreasureGenerator />} />
                <Route path="/encounter" Component={EncounterView} />
              </Routes>
            </React.Suspense>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
