import { Card, CardContent, Typography, Box } from "@mui/material";
import { Treasures } from "./dataProviders/treasures";
import CoinsDisplay from "./CoinsDisplay";
import GemsOrArtDisplay from "./GensOrArtDisplay";
import ItemsDisplay from "./ItemsDisplay";

const TreasureCard: React.FC<{
    title: string;
    treasure: Treasures | undefined;
  }> = ({ title, treasure }) => (
    <Card
      sx={{
        width: {
          xs: 1.0,
          sm: 550,
          md: 600,
        },
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Box>
          <Typography variant="subtitle1">Items:</Typography>
          <ItemsDisplay items={treasure?.items} />
        </Box>
        <Box>
          <Typography variant="subtitle1">Coins:</Typography>
          <CoinsDisplay coins={treasure?.coins} />
        </Box>
        <Box>
          <Typography variant="subtitle1">Gems and Art:</Typography>
          <GemsOrArtDisplay gemsOrArt={treasure?.gemsOrArt} />
        </Box>
      </CardContent>
    </Card>
  );

  export default TreasureCard;
