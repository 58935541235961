import React from 'react';
import { Chip } from '@mui/material';
import { Coins } from './dataProviders/treasures';

interface CoinsDisplayProps {
    coins: Coins | null | undefined;
}

const CoinsDisplay: React.FC<CoinsDisplayProps> = ({ coins }) => (
    <>
        {coins && Object.entries(coins).map(([key, value]) => {
            if (value) {
                return (
                    <Chip key={key} label={`${value} ${key}`} variant="outlined" />
                );
            }
            return null;
        })}
    </>
);

export default CoinsDisplay;
