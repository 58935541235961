import MonsterSearch from "./MonsterSearch";
import { ParticipantForm } from "./ParticipantForm";
import { useEncounterState } from "./useEncounterState";
import { Container, Typography, Stack, Button } from "@mui/material";
import { ParticipantList } from "./ParticipantList";
import { useCallback, useEffect } from "react";

export interface EncounterParticipant {
  id: number;
  name: string;
  type: "player" | "monster";
  initiative: number;
  hp: number;
  maxHp: number;
  ac: number;
  conditions: string[];
  monsterDetails?: MonsterDetails;
}

export interface MonsterDetails {
  name: string;
  size: string;
  type: string;
  alignment: string;
  hit_points: number;
  armor_class: { value: number }[];
  speed: { walk: number };
  actions: { name: string; desc: string }[];
  special_abilities: { name: string; desc: string }[];
  image: string;
}

const EncounterBuilder = () => {
  const {
    players,
    monsters,
    encounterStarted,
    addPlayer,
    addMonster,
    endEncounter,
    removeParticipant,
    startEncounter,
  } = useEncounterState();

  useEffect(() => {
    const handleChannelMessage = (event: MessageEvent) => {
      if (event.data === "endEncounter") {
        endEncounter();
      }
    };

    const encounterChannel = new BroadcastChannel("encounterChannel");
    encounterChannel.addEventListener("message", handleChannelMessage);

    return () => {
      encounterChannel.removeEventListener("message", handleChannelMessage);
      encounterChannel.close();
    };
  }, [endEncounter]);

  const handleOpenEncounter = useCallback(() => {
    startEncounter();
    const url = "/encounter";
    window.open(url, "_blank");
  }, [startEncounter]);

  return (
    <Container maxWidth="md">
      {
        <div>
          {!encounterStarted && (
            <>
              <Typography variant="h2">Encounter Builder</Typography>
              <Stack spacing={2} direction="column">
                <Typography variant="h4">Add player:</Typography>
                <ParticipantForm
                  participantType="player"
                  onAddParticipant={addPlayer}
                />
                <Typography variant="h4">Add monster:</Typography>
                <MonsterSearch onMonsterSelect={addMonster} />
                <Typography variant="h4">Participants:</Typography>

                <ParticipantList
                  participants={players.concat(monsters)}
                  onRemoveParticipant={removeParticipant}
                />
              </Stack>
            </>
          )}
          <Stack spacing={2} direction="column">
            <Button variant="outlined" onClick={handleOpenEncounter}>
              Start Encounter
            </Button>
          </Stack>
        </div>
      }
    </Container>
  );
};

export default EncounterBuilder;
