import * as React from "react";
import { EncounterParticipant } from "./EncounterBuilder";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { Delete } from "@mui/icons-material";

interface ParticipantListProps {
  participants: EncounterParticipant[];
  onRemoveParticipant: (id: number, type: "player" | "monster") => void;
}

export const ParticipantList: React.FC<ParticipantListProps> = ({
  participants,
  onRemoveParticipant,
}) => {
  return (
    <>
      <List>
        {participants.map((participant) => (
          <ListItem key={participant.id}>
            <ListItemText
              primary={`${participant.name} (${participant.type}) - Initiative: ${participant.initiative}, HP: ${participant.hp}/${participant.maxHp}, AC: ${participant.ac}`}
            />
            <IconButton
              edge="end"
              color="error"
              onClick={() =>
                onRemoveParticipant(participant.id, participant.type)
              }
            >
              <Delete />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};
