import axios from 'axios';

const chatGPTApiKey = process.env.REACT_APP_CHATGPT_API_KEY;

export const generateTreasureWithPrompt = async (prompt: string) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/engines/text-davinci-002/completions',
      {
        prompt,
        max_tokens: 100,
        n: 1,
        stop: null,
        temperature: 0.8,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${chatGPTApiKey}`,
        },
      },
    );

    if (response.data.choices && response.data.choices.length > 0) {
      return response.data.choices[0].text;
      // Process the treasureText to create a Treasures object
    } else {
      throw new Error('Failed to generate treasure from ChatGPT');
    }
  } catch (error) {
    console.error('Error fetching treasure from ChatGPT:', error);
    throw error;
  }
};

export default generateTreasureWithPrompt;
