import * as React from "react";
import { Difficulty } from "./common/difficulty";
import TreasureGeneratorForm from "./TreasureGeneratorForm";
import TreasureDisplay from "./TreasureDisplay"; // Import the TreasureDisplay component
import { Treasures } from "./dataProviders/treasures";
import { ChatGPTDataProvider } from "./dataProviders/chatGPTDataProvider";
import { StaticDataProvider } from "./dataProviders/staticDataProvider";
import { TreasuresDataProvider } from "./dataProviders/treasuresDataProvider";
import Box from "@mui/material/Box/Box";
import { Typography } from "@mui/material";
import DataProviderToggle from "./dataProviders/dataProviderToggle";

const TreasureGenerator = () => {
  const [originalTreasures, setOriginalTreasures] = React.useState<Treasures>();
  const [chatGPTTreasures, setchatGPTTreasure] = React.useState<Treasures>();
  const [isTreasureGenerated, setIsTreasureGenerated] = React.useState(false);
  const [key, setKey] = React.useState<number | undefined>();
  const [activeDataProvider, setActiveDataProvider] =
    React.useState<TreasuresDataProvider>(new StaticDataProvider());

  const toggleDataProvider = () => {
    setActiveDataProvider((prevDataProvider) => {
      setOriginalTreasures(undefined);
      setchatGPTTreasure(undefined);
      return prevDataProvider instanceof StaticDataProvider
        ? new ChatGPTDataProvider()
        : new StaticDataProvider();
    });
  };

  const handleTreasureGeneration = async (
    players: number,
    level: number,
    difficulty: Difficulty
  ) => {
    const generatedTreasure = await activeDataProvider.generateTreasure(
      players,
      level,
      difficulty
    );
    setOriginalTreasures(generatedTreasure);
    setchatGPTTreasure(generatedTreasure);
    setIsTreasureGenerated(true);
    setKey(Date.now());
  };

  const handleSubmit = (
    players: number,
    level: number,
    difficulty: Difficulty
  ) => {
    handleTreasureGeneration(players, level, difficulty);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "background.paper",
      }}
    >
      <div>
        <Typography variant="h2" component="div">
          Generate Treasure
        </Typography>
        <DataProviderToggle
          activeDataProvider={activeDataProvider}
          onToggleDataProvider={toggleDataProvider}
        >
          <TreasureGeneratorForm onSubmit={handleSubmit} />
          {isTreasureGenerated && (
            <TreasureDisplay
              key={key}
              treasures={originalTreasures}
              chatGPTTreasures={chatGPTTreasures}
              useChatGPT={activeDataProvider instanceof ChatGPTDataProvider}
            />
          )}
        </DataProviderToggle>
      </div>
    </Box>
  );
};

export default TreasureGenerator;
