import React from 'react';
import { Chip } from '@mui/material';
import { GemsOrArt } from './dataProviders/treasures';

interface GemsOrArtDisplayProps {
    gemsOrArt: GemsOrArt | null | undefined;
}

const GemsOrArtDisplay: React.FC<GemsOrArtDisplayProps> = ({ gemsOrArt }) => (
    <>
        {gemsOrArt && Object.entries(gemsOrArt).map(([key, value]) => {
            if (value) {
                return (
                    <Chip key={key} label={`${value} ${key}`} variant="outlined" />
                );
            }
            return null;
        })}
    </>
);

export default GemsOrArtDisplay;
