import React from "react";
import { ChatGPTDataProvider } from "./chatGPTDataProvider";
import { TreasuresDataProvider } from "./treasuresDataProvider";
import Box from "@mui/material/Box";
import { FormControlLabel, Switch } from "@mui/material";

interface DataProviderToggleProps {
  children: React.ReactNode;
  activeDataProvider: TreasuresDataProvider;
  onToggleDataProvider: () => void;
}

export const DataProviderToggle: React.FC<DataProviderToggleProps> = ({
  children,
  activeDataProvider,
  onToggleDataProvider,
}) => {

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={activeDataProvider instanceof ChatGPTDataProvider}
              onChange={onToggleDataProvider}
            />
          }
          label="Use AI-generated Treasures"
        />
      </Box>
      {children}
    </>
  );
};

export default DataProviderToggle;
