export const trivialEncounterMagicItems = [
    "potion-of-healing",
    "spell-scroll-cantrip",
    "spell-scroll-1st-level",
    "ammunition-1",
    "potion-of-climbing",
    "oil-of-slipperiness",
    "dust-of-disappearance",
    "cloak-of-elvenkind",
    "boots-of-elvenkind",
    "eyes-of-minute-seeing",
    "gloves-of-swimming-and-climbing",
    "goggles-of-night",
    "hat-of-disguise",
    "quiver-of-ehlonna",
    "slippers-of-spider-climbing",
    "driftglobe",
    "immovable-rod",
    "rope-of-climbing",
    "bag-of-holding",
    "handy-haversack",
    "ring-of-jumping",
    "ring-of-mind-shielding",
    "ring-of-warmth",
    "wand-of-magic-detection",
    "wand-of-secrets",
    "instrument-of-the-bards-doss-lyre",
    "pipes-of-haunting",
    "pipes-of-the-sewers",
    "elven-chain",
    "mithral-armor",
    "sentinel-shield",
    "adamantine-armor",
    "mace-of-smiting",
    "mace-of-terror",
    "shortsword-of-life-stealing",
    "shortsword-of-wounding",
  ];

export const easyEncounterMagicItems = [
  "potion-of-greater-healing",
  "spell-scroll-2nd-level",
  "spell-scroll-3rd-level",
  "ammunition-2",
  "oil-of-etherealness",
  "philter-of-love",
  "ring-of-protection",
  "ring-of-swimming",
  "scroll-of-protection",
  "staff-of-the-python",
  "wand-of-web",
  "bracers-of-archery",
  "brooch-of-shielding",
  "broom-of-flying",
  "necklace-of-adaptation",
  "periapt-of-health",
  "boots-of-striding-and-springing",
  "cloak-of-protection",
  "eyes-of-the-eagle",
  "gloves-of-missile-snaring",
  "helm-of-telepathy",
  "medallion-of-thoughts",
  "ring-of-evasion",
  "ring-of-feather-falling",
  "ring-of-free-action",
  "wand-of-enemy-detection",
  "wand-of-magic-missiles",
  "wand-of-the-warmage",
  "instrument-of-the-bards-canaith-mandolin",
  "pipes-of-haunting",
  "pipes-of-the-sewers",
  "armor-of-resistance",
  "cloak-of-displacement",
  "dagger-of-venom",
  "flame-tongue",
  "frost-brand",
  "longbow-of-warning",
  "rapier-of-puncturing",
]
export const mediumEncounterMagicItems = [
  "potion-of-superior-healing",
  "spell-scroll-4th-level",
  "spell-scroll-5th-level",
  "ammunition-3",
  "dust-of-dryness",
  "giant-slayer",
  "hammer-of-thunderbolts",
  "oil-of-sharpness",
  "potion-of-frost-giant-strength",
  "potion-of-stone-giant-strength",
  "ring-of-spell-storing",
  "staff-of-healing",
  "staff-of-swarming-insects",
  "wand-of-ice-storms",
  "wand-of-lightning-bolts",
  "wand-of-paralysis",
  "belt-of-dwarvenkind",
  "boots-of-levitation",
  "bracers-of-defense",
  "cloak-of-arachnida",
  "dust-of-invisibility",
  "gem-of-seeing",
  "helm-of-teleportation",
  "ring-of-animal-influence",
  "ring-of-invisibility",
  "ring-of-spell-turning",
  "scarab-of-protection",
  "wand-of-polymorph",
  "rod-of-lordly-might",
  "instrument-of-the-bards-cliq-lyre",
  "stone-of-controlling-earth-elementals",
  "armor-of-invulnerability",
  "luck-blade",
  "mace-of-disruption",
  "maul-of-the-titans",
  "nine-lives-stealer",
  "vorpal-sword",
]
export const hardEncounterMagicItems = [
  "potion-of-supreme-healing",
  "spell-scroll-6th-level",
  "spell-scroll-7th-level",
  "ammunition-4",
  "armor-of-vulnerability",
  "belt-of-giant-strength",
  "cloak-of-invisibility",
  "crystal-ball",
  "cube-of-force",
  "horn-of-valhalla",
  "manual-of-bodily-health",
  "manual-of-gainful-exercise",
  "manual-of-golems",
  "manual-of-quickness-of-action",
  "potion-of-cloud-giant-strength",
  "potion-of-storm-giant-strength",
  "ring-of-regeneration",
  "ring-of-shooting-stars",
  "ring-of-telekinesis",
  "ring-of-three-wishes",
  "robe-of-the-archmagi",
  "rod-of-absorption",
  "rod-of-resurrection",
  "scarab-of-death",
  "sphere-of-annihilation",
  "staff-of-power",
  "staff-of-the-magi",
  "tome-of-clear-thought",
  "tome-of-leadership-and-influence",
  "tome-of-understanding",
  "wand-of-fireballs",
  "wand-of-wonder",
  "instrument-of-the-bards-anstruth-harp",
  "iron-flask",
  "efreeti-bottle",
  "staff-of-thunder-and-lightning",
  "vorpal-weapon",
]
export const deadlyEncounterMagicItems = [
  "spell-scroll-8th-level",
  "spell-scroll-9th-level",
  "ammunition-5",
  "book-of-exalted-deeds",
  "book-of-vile-darkness",
  "deck-of-many-things",
  "eye-of-vecna",
  "hand-of-vecna",
  "head-of-vecna",
  "orb-of-dragonkind",
  "ring-of-djinni-summoning",
  "ring-of-elemental-command",
  "ring-of-epic-spells",
  "ring-of-ram",
  "ring-of-x-ray-vision",
  "robe-of-stars",
  "robe-of-useful-items",
  "rod-of-rulership",
  "scepter-of-savras",
  "staff-of-the-woodlands",
  "talisman-of-pure-good",
  "talisman-of-the-sphere",
  "talisman-of-ultimate-evil",
  "wand-of-negation",
  "wand-of-oro-arch",
  "weapon-of-legacy",
  "well-of-many-worlds",
  "amulet-of-the-planes",
  "candle-of-invocation",
  "carpet-of-flying",
  "crystal-ball-with-true-seeing",
  "figurine-of-wondrous-power",
  "horn-of-blasting",
  "ioun-stone",
  "mirror-of-life-trapping",
  "necklace-of-fireballs",
  "necklace-of-prayer-beads",
  "periapt-of-proof-against-poison",
  "periapt-of-wound-closure",
  "portable-hole",
  "ring-of-arcanum",
  "ring-of-energy-resistance",
  "ring-of-genie-summoning",
  "ring-of-haste",
  "ring-of-protection-from-energy",
  "ring-of-spell-resistance",
  "ring-of-spell-storing-greater",
  "ring-of-wizardry",
  "robe-of-eyes",
  "rod-of-alertness",
  "rod-of-security",
  "slippers-of-speed",
  "sphere-of-ultimate-destruction",
  "staff-of-frost",
  "staff-of-illumination",
  "staff-of-withering",
  "stone-of-good-luck",
  "tome-of-the-stilled-tongue",
  "trident-of-fish-command",
  "universal-solvent",
  "vicious-weapon",
  "wand-of-binding",
  "wand-of-entropy",
  "wand-of-illusions",
  "wand-of-meteors",
  "wand-of-miracle",
  "wand-of-time-stop",
  "weapon-of-speed",
  "weapon-of-warning",
  "wings-of-flying",
  "apparatus-of-kwalish",
  "belt-of-stoneskin",
  "bracers-of-universal-energy-resistance",
  "cloak-of-the-bat",
  "cloak-of-the-manta-ray",
  "dust-of-appearance",
  "gloves-of-storing",
  "gloves-of-the-balanced-hand",
  "helm-of-brilliance",
  "horn-of-blasting-greater",
  "ioun-stone-greater",
  "mirror-of-opposition",
  "necklace-of-adaptation",
  "periapt-of-foul-rotting",
  "periapt-of-health-greater",
  "ring-of-continuation",
  "ring-of-epic-wizardry",
  "ring-of-natural-attacks",
  "ring-of-revival",
  "ring-of-the-four-winds",
  "scarab-of-protection-greater",
  "wand-of-epic-spells",
  "weapon-of-legacy-greater",
]