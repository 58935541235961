import React, { useState, useCallback } from "react";
import { Box } from "@mui/material";
import { ToggleButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

const conditionsData = [
  { label: "Blinded", emoji: "🙈" },
  { label: "Charmed", emoji: "💞" },
  { label: "Deafened", emoji: "🙉" },
  { label: "Frightened", emoji: "😱" },
  { label: "Grappled", emoji: "🤼" },
  { label: "Incapacitated", emoji: "😵" },
  { label: "Invisible", emoji: "👻" },
  { label: "Paralyzed", emoji: "⚡" },
  { label: "Petrified", emoji: "🗿" },
  { label: "Poisoned", emoji: "☠️" },
  { label: "Prone", emoji: "🧎" },
  { label: "Restrained", emoji: "⛓️" },
  { label: "Stunned", emoji: "🌀" },
  { label: "Unconscious", emoji: "💤" },
];

interface ConditionManagerProps {
  conditions: string[];
  onConditionsChange: (conditions: string[]) => void;
}

// Wrap the component with React.memo to prevent unnecessary re-renders
export const ConditionManager = React.memo<ConditionManagerProps>(
  ({ conditions, onConditionsChange }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    // Use useCallback to memoize the handleConditionChange function
    // and only recreate it when conditions or onConditionsChange change
    const handleConditionChange = useCallback(
      (conditionDataLabel: string) => {
        const newConditions = conditions.includes(conditionDataLabel)
          ? conditions.filter((condition) => condition !== conditionDataLabel)
          : [...conditions, conditionDataLabel];
        onConditionsChange(newConditions);
      },
      [conditions, onConditionsChange]
    );

    return (
      <Box mt={1} display="flex" alignItems="center">
        {conditions?.map((condition) => {
          const conditionData = conditionsData.find(
            (c) => c.label === condition
          );
          return (
            <Box key={condition} mr={1}>
              {conditionData?.emoji}
            </Box>
          );
        })}
        <IconButton onClick={handleClickOpen} size="small">
          <InfoIcon />
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Conditions</DialogTitle>
          <DialogContent>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {conditionsData.map((conditionData) => (
                // Use the memoized handleConditionChange function for the onChange event
                <ToggleButton
                  key={conditionData.label}
                  value={conditionData.label}
                  selected={conditions?.includes(conditionData.label)}
                  onChange={() => handleConditionChange(conditionData.label)}
                >
                  {conditionData.emoji} {conditionData.label}
                </ToggleButton>
              ))}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    );
  }
);
