// EncounterView.tsx
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { EncounterList } from "./EncounterList";
import { useEncounterState } from "./useEncounterState";
import { useMemo } from "react";

const EncounterView = () => {
  const {
    players,
    monsters,
    encounterStarted,
    turn,
    applyDamage,
    handleConditionsChange,
    endEncounter,
    nextTurn,
    roundNumber,
  } = useEncounterState();

  const participants = players.concat(monsters);
  const sortedParticipants = useMemo(() => {
    const sorted = [...participants].sort(
      (a, b) => b.initiative - a.initiative
    );
    return encounterStarted
      ? [...sorted.slice(turn), ...sorted.slice(0, turn)]
      : sorted;
  }, [participants, encounterStarted, turn]);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div">
            Round: {roundNumber}
          </Typography>
          <Box flexGrow={1} />
          <Typography variant="h6" component="div">
            Next up is: {sortedParticipants[1]?.name}
          </Typography>
          <Box flexGrow={1} />
          <Button variant="contained" onClick={nextTurn}>
            Next Turn
          </Button>
          <Box flexGrow={1} />
          <Button variant="contained" onClick={endEncounter}>
            End Encounter
          </Button>
        </Toolbar>
      </AppBar>
      {encounterStarted && (
        <EncounterList
          participants={players.concat(monsters)}
          encounterStarted={true}
          turn={turn}
          handleApplyDamage={applyDamage}
          handleConditionsChange={handleConditionsChange}
        />
      )}
    </>
  );
};

export default EncounterView;
