// useEncounterState.ts
import { useEffect, useMemo, useState } from "react";
import { EncounterParticipant } from "./EncounterBuilder";
import { Monster } from "./MonsterSearch";
import { MonsterDetails } from "./EncounterBuilder";

const initialState = {
  players: [],
  monsters: [],
  encounterStarted: false,
  turn: 0,
  roundNumber: 1,
};

export const useEncounterState = () => {
  const broadcastChannel = useMemo(
    () => new BroadcastChannel("encounterChannel"),
    []
  );
  const [state, setState] = useState(() => {
    const storedState = localStorage.getItem("encounterState");
    console.log("storedState from localStorage:", storedState);
    return storedState ? JSON.parse(storedState) : initialState;
  });
  const { players, monsters, encounterStarted, turn, roundNumber } = state;

  useEffect(() => {
    localStorage.setItem("encounterState", JSON.stringify(state));
  }, [state]);

  const addPlayer = (player: EncounterParticipant) => {
    setState((prevState: { players: EncounterParticipant[] }) => ({
      ...prevState,
      players: [...prevState.players, player],
    }));
  };

  const addMonster = (monster: Monster) => {
    fetch(`https://www.dnd5eapi.co${monster.url}`)
      .then((response) => response.json())
      .then((monsterData) => {
        const newMonster = {
          id: Date.now(),
          name: monsterData.name,
          type: "monster",
          initiative: rollInitiative(monsterData.dexterity), // You can set this value as needed
          hp: monsterData.hit_points,
          maxHp: monsterData.hit_points,
          ac: monsterData.armor_class[0].value,
          conditions: [],
          monsterDetails: monsterData as MonsterDetails,
        };

        setState((prevState: { monsters: EncounterParticipant[] }) => ({
          ...prevState,
          monsters: [...prevState.monsters, newMonster as EncounterParticipant],
        }));
      });
  };

  const removeParticipant = (id: number, type: "player" | "monster") => {
    setState(
      (prevState: {
        players: EncounterParticipant[];
        monsters: EncounterParticipant[];
      }) => {
        if (type === "player") {
          return {
            ...prevState,
            players: prevState.players.filter((player) => player.id !== id),
          };
        } else {
          return {
            ...prevState,
            monsters: prevState.monsters.filter((monster) => monster.id !== id),
          };
        }
      }
    );
  };

  const handleConditionsChange = (
    participantId: number,
    conditions: string[]
  ) => {
    setState(
      (prevState: {
        players: EncounterParticipant[];
        monsters: EncounterParticipant[];
      }) => {
        const updateConditions = (participant: EncounterParticipant) => ({
          ...participant,
          conditions,
        });

        return {
          ...prevState,
          players: prevState.players.map((player) =>
            player.id === participantId ? updateConditions(player) : player
          ),
          monsters: prevState.monsters.map((monster) =>
            monster.id === participantId ? updateConditions(monster) : monster
          ),
        };
      }
    );
  };
  function calculateDexterityModifier(dexterity: number): number {
    return Math.floor((dexterity - 10) / 2);
  }

  const rollInitiative = (dexterity: number): number => {
    const dexterityModifier = calculateDexterityModifier(dexterity);
    const roll = Math.floor(Math.random() * 20) + 1;
    const modifiedRoll = roll + dexterityModifier;
    return modifiedRoll < 0 ? 0 : modifiedRoll;
  };

  const startEncounter = () => {
    setState((prevState: any) => ({
      ...prevState,
      encounterStarted: true,
    }));
  };
  const nextTurn = () => {
    setState(
      (prevState: {
        turn: number;
        players: string | any[];
        monsters: string | any[];
        roundNumber: number;
      }) => {
        const newTurn =
          (prevState.turn + 1) %
          (prevState.players.length + prevState.monsters.length);
        const newRoundNumber =
          newTurn === 0 ? prevState.roundNumber + 1 : prevState.roundNumber;
        return {
          ...prevState,
          turn: newTurn,
          roundNumber: newRoundNumber,
        };
      }
    );
  };

  const applyDamage = (id: number, damage: number) => {
    const updateHp = (participant: EncounterParticipant) => ({
      ...participant,
      hp: participant.hp - damage,
    });

    setState((prevState: { players: any[]; monsters: any[] }) => {
      const newPlayers = prevState.players.map((player) =>
        player.id === id && player.type === "player" ? updateHp(player) : player
      );
      const newMonsters = prevState.monsters
        .map((monster) =>
          monster.id === id && monster.type === "monster"
            ? updateHp(monster)
            : monster
        )
        .filter((monster) => !(monster.type === "monster" && monster.hp <= 0));

      return {
        ...prevState,
        players: newPlayers,
        monsters: newMonsters,
      };
    });
  };

  const endEncounter = () => {
    setState(initialState);
    broadcastChannel.postMessage("endEncounter");
  };

  return {
    players,
    monsters,
    encounterStarted,
    turn,
    roundNumber,
    addPlayer,
    addMonster,
    startEncounter,
    nextTurn,
    applyDamage,
    endEncounter,
    handleConditionsChange,
    removeParticipant,
  };
};
