import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { Treasures } from "./dataProviders/treasures";
import TreasureCard from "./TreasureCard";

interface TreasureDisplayProps {
  treasures: Treasures | undefined;
  chatGPTTreasures: Treasures | undefined;
  useChatGPT: boolean;
}

const TreasureDisplay: React.FC<TreasureDisplayProps> = ({
  treasures,
  chatGPTTreasures,
  useChatGPT,
}) => {
  if (!treasures) {
    return null;
  }

  if (useChatGPT) {
    return (
      <div>
        <Typography variant="h5" component="div" gutterBottom>
          AI Generated Treasure
        </Typography>
        <Paper
          sx={{
            padding: 2,
            maxHeight: "20rem",
            maxWidth: "50rem",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="body1"
            component="pre"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {chatGPTTreasures?.items?.[0]?.description ??
              "No description available"}
          </Typography>
        </Paper>
      </div>
    );
  }

  return (
    <Grid container spacing={2}>
      {treasures && (
        <Grid item xs={12} sm={6}>
          <TreasureCard title="Original Treasure" treasure={treasures} />
        </Grid>
      )}
    </Grid>
  );
};

export default TreasureDisplay;
