import axios from "axios";

const dnd5eapi = axios.create({
  baseURL: 'https://www.dnd5eapi.co/api/',
});

export const fetchMagicItemByName = async (name: string) => {
    try {
      const response = await dnd5eapi.get(`magic-items/${encodeURIComponent(name)}`);
      return response.data;
    } catch (error: any) {
      console.error('Error fetching magic item:', error);

      if (error.response?.status === 404) {
        const errorWithStatus = new Error('Not found');
        (errorWithStatus as any).status = 404;
        throw errorWithStatus;
      }

      return null;
    }
  };

export default dnd5eapi;
