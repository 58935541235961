import React, { useCallback, useEffect, useState } from "react";
import Fuse from "fuse.js";
import TextField from "@mui/material/TextField/TextField";
import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

export interface Monster {
  index: string;
  name: string;
  url: string;
}

interface MonsterSearchProps {
  onMonsterSelect: (monster: Monster) => void;
}

const fuseOptions = {
  keys: ["name"],
  threshold: 0.3, // Adjust this value to control the fuzziness
};

const MonsterSearch: React.FC<MonsterSearchProps> = React.memo(
  ({ onMonsterSelect }) => {
    const [monsters, setMonsters] = useState<Monster[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState<Monster[]>([]);

    useEffect(() => {
      // Fetch monsters from the API
      fetch("https://www.dnd5eapi.co/api/monsters")
        .then((response) => response.json())
        .then((data) => setMonsters(data.results));
    }, []);

    useEffect(() => {
      const fuse = new Fuse(monsters, fuseOptions);
      setSearchResults(fuse.search(searchTerm).map((result) => result.item));
    }, [searchTerm, monsters]);

    const handleClickAway = useCallback(() => {
      setSearchResults([]);
      setSearchTerm("");
    }, []);

    return (
      <div>
        <TextField
          label="Search for a monster"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ClickAwayListener onClickAway={handleClickAway}>
          <List>
            {searchResults.map((monster) => (
              <ListItem key={monster.index} disablePadding>
                <ListItemButton onClick={() => onMonsterSelect(monster)}>
                  <ListItemText primary={monster.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </ClickAwayListener>
      </div>
    );
  }
);

export default MonsterSearch;
