import * as React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from '@mui/material/styles';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const ThemeToggle: React.FC = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <IconButton onClick={colorMode.toggleColorMode} color="inherit">
      {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

const MainAppBar: React.FC = () => {
  const navigate = useNavigate();

  const handleGenerateTreasureClick = () => {
    navigate("/treasure");
  };

  const handleEncounterBuilderClick = () => {
    navigate("/encounterBuilder");
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">
          D20Vault
        </Typography>
        <Button color="inherit" onClick={handleGenerateTreasureClick}>
          Generate Treasure
        </Button>
        <Button color="inherit" onClick={handleEncounterBuilderClick}>
          EncounterBuilder
        </Button>
        <Box flexGrow={1} />
        <ThemeToggle />
      </Toolbar>
    </AppBar>
  );
};

export default MainAppBar;
