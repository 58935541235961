import * as React from "react";
import {
  MenuItem,
  Select,
  Typography,
  Grid,
  Slider,
  Container,
  Button,
} from "@mui/material";
import { Difficulty } from "./common/difficulty";

interface TreasureGeneratorFormProps {
  onSubmit: (players: number, level: number, difficulty: Difficulty) => void;
}

const difficultyOptions = [
  Difficulty.Trivial,
  Difficulty.Easy,
  Difficulty.Medium,
  Difficulty.Hard,
  Difficulty.Deadly,
];

const TreasureGeneratorForm: React.FC<TreasureGeneratorFormProps> = ({ onSubmit }) => {
  const [playerLevel, setPlayerLevel] = React.useState(1);
  const [numberOfPlayers, setNumberOfPlayers] = React.useState(1);
  const [difficulty, setDifficulty] = React.useState(Difficulty.Trivial);

  const handleSubmit = async () => {
    onSubmit(playerLevel, numberOfPlayers, difficulty);
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={3} alignItems="center">
        <Grid item md={6}>
          <Typography id="player-level-slider" gutterBottom>
            Players level
          </Typography>
          <Slider
            valueLabelDisplay="auto"
            defaultValue={playerLevel}
            onChange={(_, value) => setPlayerLevel(value as number)}
            step={1}
            min={1}
            marks
            max={20}
            aria-labelledby="quantity-slider"
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item md={6}>
          <Typography id="number-of-players-slider" gutterBottom>
            Number of Players
          </Typography>
          <Slider
            valueLabelDisplay="auto"
            defaultValue={numberOfPlayers}
            onChange={(_, value) => setNumberOfPlayers(value as number)}
            step={1}
            min={1}
            marks
            max={10}
            aria-labelledby="quantity-slider"
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item md={6}>
          <Typography id="difficulty-selector" gutterBottom>
            Encounter Difficulty
          </Typography>
          <Select
            labelId="difficulty-label"
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value as Difficulty)}
          >
            {difficultyOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>{" "}
        </Grid>
        <Grid item md={6}>
          <Button variant="contained" onClick={handleSubmit}>
            Generate Loot
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TreasureGeneratorForm;
