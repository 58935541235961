import * as React from "react";
import { Participant } from "./Participant";
import { EncounterParticipant } from "./EncounterBuilder";
import { Grid, Paper } from "@mui/material";
import { useMemo } from "react";

interface EncounterListProps {
  participants: EncounterParticipant[];
  encounterStarted: boolean;
  turn: number;
  handleApplyDamage: (id: number, damage: number) => void;
  handleConditionsChange: (id: number, conditions: string[]) => void;
}

export const EncounterList: React.FC<EncounterListProps> = React.memo(
  ({
    participants,
    encounterStarted,
    turn,
    handleApplyDamage,
    handleConditionsChange,
  }) => {
    const sortedParticipants = useMemo(() => {
      const sorted = [...participants].sort(
        (a, b) => b.initiative - a.initiative
      );
      return encounterStarted
        ? [...sorted.slice(turn), ...sorted.slice(0, turn)]
        : sorted;
    }, [participants, encounterStarted, turn]);

    return (
      <>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Participant
                participant={sortedParticipants[0]}
                handleApplyDamage={(damage: number) =>
                  handleApplyDamage(sortedParticipants[0].id, damage)
                }
                handleConditionsChange={handleConditionsChange}
              />
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
);
