import React, { useCallback } from "react";
import { MonsterDetails as MonsterDetailsType } from "./EncounterBuilder";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Paper,
  Grid,
  Divider,
} from "@mui/material";

const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: process.env.REACT_APP_CHATGPT_API_KEY,
});
const openai = new OpenAIApi(configuration);

interface MonsterDetailsProps {
  monsterDetails: MonsterDetailsType;
}

export const MonsterDetails: React.FC<MonsterDetailsProps> = React.memo(
  ({ monsterDetails }) => {
    const [aiHelp, setAIHelp] = React.useState<string | null>(null);
    const handleAIHelp = useCallback(async () => {
      try {
        const prompt = `Give me a round by round encounter strategy for a ${
          monsterDetails.name
        } using D&D5e rules. Available actions are: ${monsterDetails.actions
          .map((action) => `${action.name}`)
          .join(
            ","
          )}. Available special abilities are: ${monsterDetails.special_abilities
          .map((special) => `${special.name}`)
          .join(",")}. Make it very short`;

        const response = await openai.createCompletion({
          model: "text-davinci-003",
          prompt: prompt,
          temperature: 0,
          max_tokens: 4000,
        });
        if (response.data.choices && response.data.choices.length > 0) {
          setAIHelp(response.data.choices[0].text);
        }
      } catch (e) {
        console.error(e);
      }
    }, [monsterDetails]);

    return (
      <Box sx={{ width: "100%" }}>
        <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2}>
            {monsterDetails.image && (
              <Grid item xs={6}>
                <Box sx={{ mb: 2 }}>
                  <img
                    src={`https://www.dnd5eapi.co${monsterDetails?.image}`}
                    alt={monsterDetails?.name}
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Size: {monsterDetails?.size}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Type: {monsterDetails?.type}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Alignment: {monsterDetails?.alignment}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Speed: {monsterDetails?.speed.walk}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Actions
          </Typography>
          <List>
            {monsterDetails?.actions.map((action, index) => (
              <ListItem key={index}>
                <ListItemText primary={action.name} secondary={action.desc} />
              </ListItem>
            ))}{" "}
          </List>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Special Abilities
          </Typography>
          <List>
            {monsterDetails?.special_abilities.map((special, index) => (
              <ListItem key={index}>
                <ListItemText primary={special.name} secondary={special.desc} />
              </ListItem>
            ))}
          </List>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            AI Strategy
          </Typography>
          <Typography variant="body1" gutterBottom>
            {aiHelp}
          </Typography>
          <Button variant="outlined" onClick={handleAIHelp}>
            AI Strategy
          </Button>
        </Paper>
      </Box>
    );
  }
);
