import { TreasuresDataProvider } from "./treasuresDataProvider";
import { GemsOrArt, Coins, Item, Rarity, Treasures } from "./treasures";
import { fetchMagicItemByName } from "./dnd5eapi";
import {
  trivialEncounterMagicItems,
  easyEncounterMagicItems,
  mediumEncounterMagicItems,
  hardEncounterMagicItems,
  deadlyEncounterMagicItems,
} from "../dataSets/magicItemsConfig";

export class StaticDataProvider implements TreasuresDataProvider {
  private static treasuresItems: Item[] = [
    {
      name: "Sword of the Gods",
      description: "A sword that is so powerful it can kill gods",
      rarity: Rarity.Common,
      tags: ["weapon"],
    },
    {
      name: "Sword of the Devils",
      description: "A sword that is so powerful it can kill devils",
      rarity: Rarity.Legendary,
      tags: ["weapon"],
    },
    {
      name: "Axe of destruction",
      description: "A axe that destroys everything",
      rarity: Rarity.Artifact,
      tags: ["weapon"],
    },
    // Add more items here as needed
  ];
  private static gemsOrArt: GemsOrArt = {
    gems: 1,
    art: 1,
  };
  private static treasures: Treasures = {
    items: null,
    coins: null,
    gemsOrArt: null,
  };

  async getLoot(): Promise<any[]> {
    return [];
  }

  async getItemById(id: string): Promise<any> {
    return "";
  }

  async generateTreasure(
    players: number,
    level: number,
    difficulty: Difficulty
  ): Promise<Treasures> {
    const range = treasureRanges.find((r) => r.difficulty === difficulty);

    if (!range) {
      throw new Error(
        "No matching treasure range found for the given difficulty"
      );
    }

    const coins: Coins = {
      gold: getRandomInt(range.coinsRange[0], range.coinsRange[1]),
    };

    const gemsOrArt: GemsOrArt = {
      gems: getRandomInt(range.gemsOrArtRange[0], range.gemsOrArtRange[1]),
      art: getRandomInt(range.gemsOrArtRange[0], range.gemsOrArtRange[1]),
    };

    function getRandomMagicItemName(range: TreasureRange) : string {
      const randomNumber = Math.floor(Math.random() * range.magicItems.length);
      const randomMagicItemName = range.magicItems[randomNumber];
      return randomMagicItemName;
    }

    const maxRetries = 10;

    const fetchValidMagicItem = async (
      itemName: string,
      range: TreasureRange,
      retries = 0
    ): Promise<any> => {
      if (retries >= maxRetries) {
        throw new Error(
          `Failed to fetch a valid magic item after ${maxRetries} retries`
        );
      }

      try {
        const magicItemData = await fetchMagicItemByName(itemName);
        return magicItemData;
      } catch (error: any) {
        if (error.status === 404) {
          return fetchValidMagicItem(getRandomMagicItemName(range), range, retries + 1);
        }

        throw new Error(`Failed to fetch magic item data for ${itemName}`);
      }
    };

    // Fetch magic item details from dnd5eapi.co using the randomMagicItemName
    const magicItemData = await fetchValidMagicItem(getRandomMagicItemName(range), range);
    if (!magicItemData) {
      throw new Error(
        `Failed to fetch magic item data for ${getRandomMagicItemName(range)}`
      );
    }

    const magicItem: Item = {
      name: magicItemData.name,
      description: magicItemData.desc[1],
      tags: magicItemData.desc[0].split(","),
      rarity: magicItemData.rarity.name,
    };

    StaticDataProvider.treasures.coins = coins;
    StaticDataProvider.treasures.gemsOrArt = gemsOrArt;
    StaticDataProvider.treasures.items = [magicItem];
    return StaticDataProvider.treasures;
  }
}

enum Difficulty {
  Trivial = "Trivial",
  Easy = "Easy",
  Medium = "Medium",
  Hard = "Hard",
  Deadly = "Deadly",
}

interface TreasureRange {
  difficulty: Difficulty;
  coinsRange: [number, number];
  gemsOrArtRange: [number, number];
  magicItems: string[];
}

const treasureRanges: TreasureRange[] = [
  {
    difficulty: Difficulty.Trivial,
    coinsRange: [10, 50],
    gemsOrArtRange: [1, 5],
    magicItems: trivialEncounterMagicItems,
  },
  {
    difficulty: Difficulty.Easy,
    coinsRange: [50, 200],
    gemsOrArtRange: [5, 20],
    magicItems: easyEncounterMagicItems,
  },
  {
    difficulty: Difficulty.Medium,
    coinsRange: [200, 500],
    gemsOrArtRange: [20, 50],
    magicItems: mediumEncounterMagicItems,
  },
  {
    difficulty: Difficulty.Hard,
    coinsRange: [500, 1000],
    gemsOrArtRange: [50, 100],
    magicItems: hardEncounterMagicItems,
  },
  {
    difficulty: Difficulty.Deadly,
    coinsRange: [1000, 5000],
    gemsOrArtRange: [100, 500],
    magicItems: deadlyEncounterMagicItems,
  },
  // Add more ranges for other difficulties
];

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
