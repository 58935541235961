import * as React from "react";
import { EncounterParticipant } from "./EncounterBuilder";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import { Box, Typography } from "@mui/material";
import { ConditionManager } from "./ConditionManager";
import { MonsterDetails } from "./MonsterDetails";

interface ParticipantProps {
  participant: EncounterParticipant;
  handleApplyDamage: (damage: number) => void;
  handleConditionsChange: (id: number, conditions: string[]) => void;
}

export const Participant: React.FC<ParticipantProps> = ({
  participant,
  handleApplyDamage,
  handleConditionsChange,
}) => {
  const [damage, setDamage] = React.useState(0);

  const {
    id,
    name,
    type,
    initiative,
    hp,
    maxHp,
    ac,
    conditions,
    monsterDetails,
  } = participant;

  return (
    <Box>
      <Typography variant="h5">
        {`${name} (${type}) - Initiative: ${initiative}, HP: ${hp}/${maxHp}, AC: ${ac}`}
      </Typography>
      <TextField
        type="number"
        placeholder="Damage"
        value={damage}
        onChange={(e) => {
          const newDamage = parseInt(e.target.value);
          setDamage(isNaN(newDamage) ? 0 : newDamage);
        }}
        size="small"
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleApplyDamage(damage)}
      >
        Apply Damage
      </Button>
      <ConditionManager
        conditions={conditions}
        onConditionsChange={(newConditions: string[]) =>
          handleConditionsChange(id, newConditions)
        }
      />
      {type === "monster" && monsterDetails && (
        <MonsterDetails monsterDetails={monsterDetails} />
      )}
    </Box>
  );
};
