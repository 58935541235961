export type Treasures = {
    items: Item[] | null;
    coins: Coins | null;
    gemsOrArt: GemsOrArt | null;
}

export type GemsOrArt = {
    gems: number | null;
    art: number | null;
}

export type Coins = {
    gold: number | null;
}

export type Item = {
    rarity: string;
    description: string;
    name: string;
    tags: string[];
}

export enum Rarity {
    Common = 'Common',
    Uncommon = 'Uncommon',
    Rare = 'Rare',
    VeryRare = 'Very Rare',
    Legendary = 'Legendary',
    Artifact = 'Artifact',
    Varies = 'Varies',
    UnknownRarity = 'Unknown Rarity',
}
