import * as React from "react";
import { EncounterParticipant } from "./EncounterBuilder";
import { TextField, Button, Stack } from "@mui/material";

interface ParticipantFormProps {
  participantType: "player" | "monster";
  onAddParticipant: (participant: EncounterParticipant) => void;
}

export const ParticipantForm: React.FC<ParticipantFormProps> = ({
  participantType,
  onAddParticipant,
}) => {
  const [participantName, setParticipantName] = React.useState("");
  const [initiative, setInitiative] = React.useState(0);
  const [hp, setHp] = React.useState(0);
  const [ac, setAc] = React.useState(0);

  const handleAddParticipant = () => {
    if (!participantName.trim()) return;

    const participant: EncounterParticipant = {
      id: Date.now(),
      name: participantName,
      type: participantType,
      initiative: initiative,
      hp: hp,
      maxHp: hp,
      ac: ac,
      conditions: [],
    };
    onAddParticipant(participant);

    setParticipantName("");
    setInitiative(0);
    setHp(0);
    setAc(0);
  };

  return (
    <div>
      <Stack spacing={1} direction="row">
        <TextField
          label="Name"
          variant="outlined"
          value={participantName}
          onChange={(e) => setParticipantName(e.target.value)}
        />
        <TextField
          type="number"
          label="Initiative"
          variant="outlined"
          value={initiative}
          onChange={(e) => {
            const newInitiative = parseInt(e.target.value);
            setInitiative(isNaN(newInitiative) ? 0 : newInitiative);
          }}
        />
        <TextField
          type="number"
          label="HP"
          variant="outlined"
          value={hp}
          onChange={(e) => {
            const newHp = parseInt(e.target.value);
            setHp(isNaN(newHp) ? 0 : newHp);
          }}
        />
        <TextField
          type="number"
          label="AC"
          variant="outlined"
          value={ac}
          onChange={(e) => {
            const newAc = parseInt(e.target.value);
            setAc(isNaN(newAc) ? 0 : newAc);
          }}
        />
        <Button variant="outlined" onClick={handleAddParticipant}>
          Add
        </Button>
      </Stack>
    </div>
  );
};
