import React from "react";
import { Typography, Box, Chip } from "@mui/material";
import { Item } from "./dataProviders/treasures";

interface ItemsDisplayProps {
  items: Item[] | null | undefined;
}

const ItemsDisplay: React.FC<ItemsDisplayProps> = ({ items }) => (
  <Box>
    {items?.map((item, index) => (
      <Box key={index} sx={{ mb: 1 }}>
        <Typography variant="h6">{item.name}</Typography>
        <Typography variant="body1">{item.description}</Typography>
        {item.tags.map((tag, index) => (
          <Chip key={index} label={tag} variant="outlined" />
        ))}
      </Box>
    ))}
  </Box>
);

export default ItemsDisplay;
