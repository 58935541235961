import { TreasuresDataProvider } from "./treasuresDataProvider";
import { Treasures } from "./treasures";
import { Difficulty } from "../common/difficulty";
import generateTreasureWithPrompt from "./chatGPTapi";

export class ChatGPTDataProvider implements TreasuresDataProvider {
  async getLoot(): Promise<any[]> {
    return [];
  }

  async getItemById(id: string): Promise<any> {
    return "";
  }

  async generateTreasure(
    players: number,
    level: number,
    difficulty: Difficulty
  ): Promise<Treasures> {
    // Implement the logic to call the ChatGPT API
    // and process the response to generate a fitting treasure.
    const prompt = `Generate a fitting treasure for a D&D 5e party with ${players} players at level ${level} and an encounter difficulty of ${difficulty}. It must contain 1 magical item, gold in quantity, gems with description and total worth in gold, art objects with description and total worth in gold. The output format must be; Magical Item: ....., Gold: ...., Gems: ..... worth ... gold, Art Objects: ...... worth ... gold`;
    const treasureText = await generateTreasureWithPrompt(prompt);

    const treasures: Treasures = {
      items: null,
      coins: null,
      gemsOrArt: null,
    };

    const myItem = [
      {
        name: "From chatGPT",
        description: treasureText,
        rarity: "",
        tags: ["weapon"],
      },
    ];

    treasures.items = myItem;

    return treasures;
  }
}
